import { createStitches } from "@stitches/react"
import type { PropertyValue } from "@stitches/react"

export const {
    styled,
    getCssText,
    theme,
    globalCss
} = createStitches({
    theme: {
        colors: {
            N50: "#F8FAFC",
            N100: "#F1F5F9",
            N200: "#E2E9F0",
            N300: "#CBD5E1",
            N400: "#94A3B8",
            N500: "#64748B",
            N600: "#475569",
            N700: "#334155",
            N800: "#1E293B",
            N900: "#0F172A",
            R200: "#FAC8CD",
            R300: "#FA96A1",
            R400: "#FC6576",
            R500: "#FF0000",
            R600: "#E20019",
            R700: "#B20014",
            Y200: "#F7F0D9",
            Y300: "#F7E5A8",
            Y400: "#F7D977",
            Y500: "#FFF200",
            Y900: "#E5BA29",
            G200: "#ECFDF5",
            G300: "#6EE7B7",
            G400: "#10B981",
            G500: "#047857",
            G800: "#064E3B",
            O200: "#FFFBEB",
            O300: "#FCD34D",
            O400: "#F59E0B",
            O500: "#B45309",
            O800: "#78350F",
            ERR200: "#FEF2F2",
            ERR300: "#EBBEBE",
            ERR400: "#EF4444",
            ERR500: "#B91C1C",
            ERR800: "#7F1D1D",
            white: "#fff",
            black: "#000"
        },
        fonts: {
            heading: "Norwester, 'Helvetica Neue', Helvetica, Arial, sans-serif",
            body: "Ubuntu, 'Helvetica Neue', Helvetica, Arial, sans-serif"
        },
        fontWeights: {
            light: 300,
            regular: 400,
            medium: 500,
            semiBold: 600,
            bold: 700,
            extraBold: 800,
            black: 900
        },
        // Choose logic pixel values according to the design.
        fontSizes: {
            desktopH1: "5rem",
            desktopH2: "3.75rem",
            desktopH3: "3.0625rem",
            desktopH4: "2.4375rem",
            desktopH5: "2rem",
            desktopH6: "1.25rem",
            desktopLarge: "1.25rem",
            desktopBody: "1rem",
            desktopSmall: "0.8125rem",
            mobileH1: "3.0625rem",
            mobileH2: "2.4375rem",
            mobileH3: "2rem",
            mobileH4: "1.25rem",
            mobileH5: "1.25rem",
            mobileH6: "1.25rem",
            mobileLarge: "1.25rem",
            mobileBody: "1rem",
            mobileSmall: "0.8125rem"
        }
    },
    media: {
        xsDown: "(max-width: 400px)",
        smDown: "(max-width: 640px)",
        mdDown: "(max-width: 768px)",
        lgDown: "(max-width: 1024px)",
        xlDown: "(max-width: 1280px)",
        "2xlDown": "(max-width: 1536px)",
        smUp: "(min-width: 640px)",
        mdUp: "(min-width: 768px)",
        lgUp: "(min-width: 1024px)",
        xlUp: "(min-width: 1280px)",
        "2xlUp": "(min-width: 1536px)"
    },
    utils: {
        m: (value: PropertyValue<"margin">) => ({
            margin: value
        }),
        mt: (value: PropertyValue<"margin">) => ({
            marginTop: value
        }),
        mr: (value: PropertyValue<"margin">) => ({
            marginRight: value
        }),
        mb: (value: PropertyValue<"margin">) => ({
            marginBottom: value
        }),
        ml: (value: PropertyValue<"margin">) => ({
            marginLeft: value
        }),
        mx: (value: PropertyValue<"margin">) => ({
            marginLeft: value,
            marginRight: value
        }),
        my: (value: PropertyValue<"margin">) => ({
            marginTop: value,
            marginBottom: value
        }),
        p: (value: PropertyValue<"padding">) => ({
            padding: value
        }),
        pt: (value: PropertyValue<"padding">) => ({
            paddingTop: value
        }),
        pr: (value: PropertyValue<"padding">) => ({
            paddingRight: value
        }),
        pb: (value: PropertyValue<"padding">) => ({
            paddingBottom: value
        }),
        pl: (value: PropertyValue<"padding">) => ({
            paddingLeft: value
        }),
        px: (value: PropertyValue<"padding">) => ({
            paddingLeft: value,
            paddingRight: value
        }),
        py: (value: PropertyValue<"padding">) => ({
            paddingTop: value,
            paddingBottom: value
        }),
        // Always use this when setting a `fontSize`, except if you use a theme defined fontSize.
        fontSizePxToRem: (value: number) => ({
            fontSize: `${value / 16}rem`
        })
    }
})

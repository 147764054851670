import {globalStyles} from "@theme/globalStyles"
import {ClientProvider} from "./src/hooks/useClient";
import makeClient from "./src/graphql/makeClient";
import * as React from "react";

export const wrapRootElement = ({element}) => {
    globalStyles()
    return <ClientProvider makeClient={makeClient}>
        {element}
    </ClientProvider>
}

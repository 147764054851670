import { CSS } from "@stitches/react"
import { globalCss, theme } from "@theme/stitches.config"
import { normalize } from "stitches-normalize-css"
import "@fontsource/norwester"
import "@fontsource/ubuntu/400.css"
import "@fontsource/ubuntu/500.css"
// eslint-disable-next-line import/no-unresolved
import "swiper/css/bundle"

const customGlobalStyles: Record<string, CSS> = {
    "*, *::before, *::after": {
        boxSizing: "border-box"
    },
    html: {
        textSizeAdjust: "100%"
    },
    body: {
        backgroundColor: theme.colors.N900,
        fontFamily: theme.fonts.body,
        fontWeight: theme.fontWeights.regular,
        fontSize: "1rem",
        lineHeight: 1.5,
        color: theme.colors.white,
        margin: 0
    },
    iframe: {
        border: 0,
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },
    ".iframe-wrapper": {
        position: "relative",
        paddingBottom: "56.25%", /* 16:9 */
        paddingTop: 25,
        height: 0,
        marginBottom: 10
    },
    ".gatsby-focus-wrapper": {
        overflow: "hidden"
    },
    "h1, h2, h3, h4, h5, h6": {
        fontFamily: theme.fonts.heading,
        fontWeight: theme.fontWeights.regular,
        margin: 0,
        mb: 16,
        textTransform: "uppercase",
        lineHeight: 1
    },
    h1: {
        fontSize: theme.fontSizes.mobileH1,
        "@lgUp": {
            fontSize: theme.fontSizes.mobileH1
        },
        "@xlUp": {
            fontSize: theme.fontSizes.desktopH1
        }
    },
    h2: {
        fontSize: theme.fontSizes.mobileH2,
        "@mdUp": {
            fontSize: theme.fontSizes.desktopH2
        }
    },
    h3: {
        fontSize: theme.fontSizes.mobileH3,
        "@mdUp": {
            fontSize: theme.fontSizes.desktopH3
        }
    },
    h4: {
        fontSize: theme.fontSizes.mobileH4,
        "@mdUp": {
            fontSize: theme.fontSizes.desktopH4
        }
    },
    h5: {
        fontSize: theme.fontSizes.mobileH5,
        "@mdUp": {
            fontSize: theme.fontSizes.desktopH5
        }
    },
    h6: {
        fontSize: theme.fontSizes.mobileH6,
        "@mdUp": {
            fontSize: theme.fontSizes.desktopH6
        }
    },
    p: {
        fontFamily: theme.fonts.body,
        fontSize: theme.fontSizes.mobileBody,
        fontWeight: theme.fontWeights.regular,
        margin: 0,
        mb: 24,
        img: {
            maxWidth: "100%",
            height: "auto"
        },
        "&:last-child": {
            mb: 0
        },
        "@mdUp": {
            fontSize: theme.fontSizes.desktopBody
        }
    },
    "input, select, textarea": {
        fontFamily: theme.fonts.body
    },
    a: {
        textDecoration: "none",
        color: theme.colors.R500
    },
    strong: {
        fontWeight: theme.fontWeights.bold
    },
    ".medium": {
        fontWeight: theme.fontWeights.medium
    },
    ".overline, .overline-white": {
        color: theme.colors.R500,
        fontSize: theme.fontSizes.desktopH6,
        fontFamily: theme.fonts.heading,
        textTransform: "uppercase",
        lineHeight: 1,
        "&:not(:last-child)": {
            marginBottom: 16
        }
    },
    ".overline-white": {
        color: "#fff"
    },
    ".header": {
        fontSize: theme.fontSizes.desktopH2,
        lineHeight: 1,
        fontFamily: theme.fonts.heading,
        textTransform: "uppercase",
        "@mdDown": {
            fontSize: theme.fontSizes.mobileH2
        }
    },
    ".btn": {
        color: theme.colors.white,
        appearance: "none",
        cursor: "pointer",
        display: "inline-flex",
        justifyContent: "center",
        alignItems: "center",
        border: "3px solid transparent",
        borderRadius: 4,
        padding: "10px 41px",
        fontSize: theme.fontSizes.desktopH6,
        textDecoration: "none !important",
        fontFamily: theme.fonts.heading,
        lineHeight: 1.2,
        transition: "all 200ms ease",
        outline: "none",
        textTransform: "uppercase"
    },
    ".btn-red": {
        backgroundColor: theme.colors.R600,
        "&:hover": {
            backgroundColor: "black"
        }
    },
    ".btn-grey": {
        backgroundColor: theme.colors.N900,
        "&:hover": {
            backgroundColor: theme.colors.R600
        }
    },
    ".btn-black": {
        backgroundColor: theme.colors.black,
        "&:hover": {
            backgroundColor: theme.colors.R600
        }
    },
    ".btn-outlined-red": {
        backgroundColor: "transparent",
        borderColor: theme.colors.R500,
        "&:hover": {
            backgroundColor: theme.colors.R600
        }
    },
    ".quote": {
        color: theme.colors.R500,
        fontFamily: theme.fonts.heading,
        fontWeight: theme.fontWeights.regular,
        fontSize: theme.fontSizes.desktopH4,
        textTransform: "uppercase"
    }
}

export const globalStyles = globalCss(...normalize, customGlobalStyles)

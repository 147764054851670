import * as React from "react"
import { Client, Provider } from "urql"

type ClientState = {
    resetClient?: () => void
}

export const ClientContext = React.createContext<ClientState>({
    resetClient: undefined
})

export const ClientProvider: React.FC<{ makeClient: () => Client }> = (
    { makeClient, children }
) => {
    const [client, setClient] = React.useState(makeClient())

    return (
        <ClientContext.Provider
            value={{
                resetClient: () => setClient(makeClient())
            }}
        >
            <Provider value={client}>{children}</Provider>
        </ClientContext.Provider>
    )
}

export const useClient = () => React.useContext(ClientContext)
